import { createTheme } from "@mui/material/styles";

const textColor = "#2C3A54";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      contrastText: textColor,
    },
    secondary: {
      main: "#F4F7FD",
    },
  },
  typography: {
    allVariants: {
      color: textColor,
    },
    h1: { fontSize: "2rem" },
    h2: { fontSize: "1.5rem" },
    h3: { fontSize: "1.17rem" },
    h4: { fontSize: "1rem" },
    h5: { fontSize: "0.83rem" },
    h6: { fontSize: "0.67rem" },
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          listStyle: "disc inside",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: "list-item",
        },
      },
    },
  },
});
