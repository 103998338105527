import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

interface ContentContainerProps {
  children?: React.ReactNode;
  backgroundColor: "secondary" | "primary";
}

export function ContentContainer({
  children,
  backgroundColor,
}: ContentContainerProps) {
  return (
    <Box sx={{ backgroundColor: `${backgroundColor}.main` }}>
      <Container>
        <Stack gap={2} paddingY={4}>
          {children}
        </Stack>
      </Container>
    </Box>
  );
}
