import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import PhoneIcon from "@mui/icons-material/Phone";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useState } from "react";

export function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container>
          <Toolbar sx={{ gap: 2 }}>
            <DrawerButton handleDrawerToggle={handleDrawerToggle} />
            <Typography
              variant="h3"
              component="div"
              fontWeight={700}
              sx={{ flexGrow: 1 }}
            >
              lek. Agnieszka Czekańska
            </Typography>
            <PhoneNumber hideOnMobile />
            <Email hideOnMobile />
          </Toolbar>
        </Container>
      </AppBar>
      <AppDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
    </Box>
  );
}

interface AppDrawerProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

function AppDrawer({ open, handleDrawerToggle }: AppDrawerProps) {
  const drawerWidth = 300;

  const drawerContent = (
    <Stack
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      gap={2}
      padding={2}
    >
      <PhoneNumber />
      <Email />
    </Stack>
  );

  return (
    <Box component="nav">
      <Drawer
        anchor="left"
        onClose={handleDrawerToggle}
        open={open}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
}

function DrawerButton({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) {
  return (
    <IconButton
      sx={{
        display: {
          sm: "none",
        },
      }}
      onClick={handleDrawerToggle}
    >
      <MenuIcon sx={{ color: "primary.contrastText" }} />
    </IconButton>
  );
}

function PhoneNumber({ hideOnMobile }: { hideOnMobile?: boolean }) {
  return (
    <IconWithText
      hideOnMobile={hideOnMobile}
      icon={<PhoneIcon />}
      text={"691 363 486"}
    />
  );
}

function Email({ hideOnMobile }: { hideOnMobile?: boolean }) {
  return (
    <IconWithText
      hideOnMobile={hideOnMobile}
      icon={<EmailIcon />}
      text={"neurologbiecz@gmail.com"}
    />
  );
}

interface IconWithTextProps {
  icon: React.ReactNode;
  text: string;
  hideOnMobile?: boolean;
}

function IconWithText({ icon, text, hideOnMobile }: IconWithTextProps) {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems={"center"}
      sx={{ display: { xs: hideOnMobile ? "none" : undefined, sm: "flex" } }}
    >
      {icon}
      <Typography component="span" fontWeight={600}>
        {text}
      </Typography>
    </Stack>
  );
}
