import Stack from "@mui/material/Stack";
import { ThemeProvider } from "@mui/material/styles";
import { Content1 } from "components/Content1";
import { Content2 } from "components/Content2";
import { Content3 } from "components/Content3";
import { Navbar } from "components/Navbar";
import { theme } from "theme";

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Navbar />
        <Content1 />
        <Content2 />
        <Content3 />
      </Stack>
    </ThemeProvider>
  );
}
