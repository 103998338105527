import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContentContainer } from "components/ContentContainer";
import { ListItemText } from "components/ListItemText";

export function Content1() {
  return (
    <ContentContainer backgroundColor="secondary">
      <Title />
      <Address />
      <Registration />
      <Service />
    </ContentContainer>
  );
}

function Title() {
  return (
    <Typography variant="h1">
      Agnieszka Czekańska <br />
      Specjalista neurolog <br />
      Specjalista chorób zakaźnych
    </Typography>
  );
}

function Address() {
  return (
    <Stack>
      <Typography variant="h2">Adres</Typography>
      <Typography variant="body1" component={"address"} fontStyle={"normal"}>
        Specjalistyczna Praktyka Lekarska <br />
        ul. Kromera 3A, <br />
        38-340 Biecz <br />
        województwo małopolskie
      </Typography>
    </Stack>
  );
}

function Registration() {
  return (
    <Stack>
      <Typography variant="h2">Rejestracja</Typography>
      <Typography variant="body1">
        Telefoniczna:{" "}
        <Typography component={"span"} fontWeight={600}>
          691 363 486
        </Typography>
        <br />
        Od poniedziałku do piątku w godzinach od 9:00 do 14:00
      </Typography>
    </Stack>
  );
}

function Service() {
  return (
    <Stack>
      <Typography variant="h2">Zakres usług</Typography>
      <List dense>
        <ListItem>
          <ListItemText>
            Diagnostyka i leczenie pacjentów ze schorzeniami neurologicznymi
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Diagnostyka i leczenie pacjentów z chorobami zakaźnymi
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Wykonywanie badania USG tętnic szyjnych oraz tętnic mózgowych (USG
            przezczaszkowe)
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Wykonywanie badań EMG, w tym m.in. badań w kierunku:
          </ListItemText>
          <List dense>
            <ListItem>
              <ListItemText>zespołu cieśni nadgarstka</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>zespołu kanału nerwu łokciowego</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>polineuropatii</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>miastenii</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                uszkodzenia neurogennego i miogennego mięśni
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <ListItemText>
            Interpretacja wyników badań, m.in. badań EEG oraz badań
            laboratoryjnych, w tym serologicznych
          </ListItemText>
        </ListItem>
      </List>
    </Stack>
  );
}
