import { Typography } from "@mui/material";
import { ContentContainer } from "./ContentContainer";

export function Content3() {
  return (
    <ContentContainer backgroundColor="secondary">
      <Typography variant="h3">Lokalizacja</Typography>
      <Typography variant="body2">
        <Typography fontWeight={"bold"} component={"span"} variant="body2">
          Biecz
        </Typography>{" "}
        położony jest na granicy województwa podkarpackiego i małopolskiego
        (powiat gorlicki) 18km od miasta Jasło i 14km od miasta Gorlice (przy
        DK28)
      </Typography>
    </ContentContainer>
  );
}
