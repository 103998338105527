import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ListItemText } from "components/ListItemText";

export function NeurologicalIlnesses() {
  const ilnesses = [
    "Ból głowy, w tym: migrena, ból głowy klasterowy, neuralgia nerwu trójdzielnego, przewlekłe bóle głowy, atypowe bóle głowy",
    "Zawroty głowy",
    "Ból kręgosłupa",
    "Zapalenie splotu barkowego",
    "Zespół cieśni nadgarstka, zespół kanału nerwu łokciowego",
    "Polineuropatia",
    "Choroba Parkinsona",
    "Demencja/otępienie w tym choroba Alzheimera, demencja na tle naczyniowym, mieszanym, zaburzenia pamięci i koncentracji",
    "Zaburzenia snu",
    "Zaburzenia czucia, siły mięśniowej (porażenia kończyn), węchu, smaku, widzenia (np. dwojenie obrazu)",
    "Zaburzenia mowy",
    "Drżenia",
    "Miastenia",
    "Dystonia",
    "Kurcz powiek",
    "Drżenie samoistne, inne postacie drżeń",
    "Padaczka, stany po utratach przytomności",
    "Udar mózgu, następstwa udaru mózgu",
    "Następstwa przebytego zapalenia mózgu, opon mózgowo-rdzeniowych",
    "Stwardnienie rozsiane",
    "Neuroborelioza",
    "Porażenie nerwu twarzowego obwodowe i centralne",
  ];

  return (
    <Stack>
      <Typography variant="h2">Schorzenia neurologiczne</Typography>
      <Typography>Schorzenia neurologiczne to m.in.:</Typography>
      <List dense>
        {ilnesses.map((ilness, ind) => (
          <ListItem key={ind}>
            <ListItemText>{ilness}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
