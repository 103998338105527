import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContentContainer } from "components/ContentContainer";
import { ListItemText } from "components/ListItemText";
import { NeurologicalIlnesses } from "./NeurologicalIlnesses";

export function Content2() {
  return (
    <ContentContainer backgroundColor="primary">
      <NeurologicalIlnesses />
      <ChorobyZakazne />
    </ContentContainer>
  );
}

function ChorobyZakazne() {
  return (
    <Stack>
      <Typography variant="h2">Choroby zakaźne</Typography>
      <Typography>
        Choroby zakaźne to schorzenia wywołane przez wirusy, bakterie, grzyby,
        pasożyty.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText>
            Do schorzeń wywołanych przez bakterie - krętki należy m.in.
            borelioza, której objawem może być rumień wędrujący, obwodowe
            porażenie nerwu twarzowego, zapalenie stawu.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Chorobą wirusową jest np. półpasiec (często z wysypką wzdłuż
            dermatomu i bólem neuropatycznym - neuralgią półpaśćcową).
          </ListItemText>
        </ListItem>
      </List>
    </Stack>
  );
}
